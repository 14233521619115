.calculator {
  border: 1px solid #ccc;
  border-radius: 5px;

  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 500px;
}

.calculator-screen {
  width: 100%;
  font-size: 5rem;
  height: 80px;
  border: none;
  background-color: rgb(133, 134, 148);
  color: #fff;
  text-align: right;
  padding-right: 20px;
  padding-left: 10px;
}

button {
  height: 60px;
  background-color: #e0e0e0;
  border-radius: 3px;
  border: 1px solid #c4c4c4;
  font-size: 2rem;
  color: #333;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05), inset 0 1px 0 0 rgba(255, 255, 255, 0.45), inset 0 -1px 0 0 rgba(255, 255, 255, 0.15), 0 1px 0 0 rgba(255, 255, 255, 0.15);
  text-shadow: 0 1px rgba(255, 255, 255, 0.4);
  cursor: pointer;
}

button:hover {
  background-color: #eaeaea;
}

.operator {
  color: #337cac;
  background-color: rgb(245, 145, 62);
}

.operator:hover {
  background-color: chocolate;
}

.calbtn {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.zero-btn {
  grid-column: span 2;
}
