.brand {
  font-weight: bold;
  font-size: 45px;
  text-decoration: none;
}

.site-header {
  position: relative;
  min-height: 8vh;
  margin: auto;
  padding: 5px;
  padding-bottom: 8px;
}

.site-header__wrapper {
  padding-top: 1rem;
  margin: auto;
  padding-bottom: 1rem;
}

.nav__wrapper {
  font-size: 25px;
  text-decoration: none;
  list-style-type: none;
}

.nav__item {
  text-decoration: none;
}

@media (min-width: 600px) {
  .site-header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

@media (min-width: 600px) {
  .nav__wrapper {
    display: flex;
  }
}

@media (max-width: 599px) {
  .nav__wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: -1;
    background-color: #d9f0f7;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  }

  .nav__wrapper.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

.nav__item a {
  display: block;
  padding: 1.5rem 1rem;
}

.nav__toggle {
  display: none;
}

@media (max-width: 599px) {
  .nav__toggle {
    display: block;
    position: absolute;
    right: 5%;
    top: 2vh;
    height: 4vh;
    width: 7%;
  }
}
